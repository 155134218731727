import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AppStore from "./../svg/appstore.svg"
import GooglePlay from "./../svg/google-play.svg"

function SecondPage({location}) {
  return (
    <Layout>
      <Seo title="Приложение MakaMaka" />
      <section className="hero-text">
        <h1 className="hero-headline">Место где собраны лучшие развлечения</h1>
        <p className="hero-paragraph">
          Мы запустили клуб для поиска единомышленников по интересам и жизненным
          ценностям. Дружба будет строиться во время совместных развлечений,
          развития, изучений новых хобби и любых проектах, которые будем создавать
          друг другу по всей стране.
        </p>
        <div className="badges">
          <AppStore />
          <GooglePlay />
        </div>
      </section>
    </Layout>
  )
}
export default SecondPage
